import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { formatOptionsList } from '../../../../../../../features/formatters'
import { mapListForQuery } from '../../../../../../../helpers/common'

import { getCurrentUserSelector, selectedControllerDataSelector } from '../../../../../../../modules/selectors/app'

import {
  STATUS_FILTER_AWAITING_FILES,
  STATUS_FILTER_FILES_OVERDUE,
  STATUS_FILTER_FILES_PENDING_REVIEW
} from '../../../../../../../features/components/Filters/BookedMediaStatusFilter/options'
import { MEDIA_SUB_CATEGORIES } from '../../../../../../Settings/ManageControllerTeam/ControllerInviteCreate/ControllerInviteCreateForm/fields'
import { BUYER } from '../../../../../../../constants/permissions'
import { SELECTED_SUB_CATEGORIES } from '../../../../../../../features/components/DropdownFilters/components/SubCategoriesFilter'
import { BRAND_CATEGORY } from '../../../../../../../features/components/DropdownFilters/components/AccountCategoriesFilters'
import { FILE_STATUS, pendingApprovalOption, pendingBuyerApprovalOption } from '../FileStatusFilter'
import { BUYER_FILTER } from '../../../../../../../features/components/DropdownFilters/components/BuyersFilter'

export const FILES_APPROVAL_STATUS_FILTER = 'files_approval_status'

export const ApprovalsFiltersContext = React.createContext()

export function ApprovalsDataProvider({ children, onFiltersChange }) {
  const dispatch = useDispatch()

  const { role: selectedControllerRole, [MEDIA_SUB_CATEGORIES]: selectedControllerMediaSubCategories } =
    useSelector(selectedControllerDataSelector)

  const user = useSelector(getCurrentUserSelector)

  const formattedSelectedControllerMediaSubCategories = useMemo(() => {
    return formatOptionsList({
      list: selectedControllerMediaSubCategories,
      valueName: 'id',
      labelName: 'name'
    })
  }, [selectedControllerMediaSubCategories])

  const automaticFilters = useMemo(() => {
    // automatic filters
    // Scenario 1: If member role is buyer, filter on file status: pending_buyer_approval and buyer: that user
    // Scenario 2: otherwise, filter on pending_approval with no buyer filter and if the user has asset type prefilter them.
    if (selectedControllerRole === BUYER) {
      return {
        [FILE_STATUS]: [pendingBuyerApprovalOption],
        [BUYER_FILTER]: [
          {
            value: user.id,
            label: user.full_name
          }
        ]
      }
    } else {
      return {
        [FILE_STATUS]: [pendingApprovalOption],
        [SELECTED_SUB_CATEGORIES]: formattedSelectedControllerMediaSubCategories || []
      }
    }
  }, [selectedControllerRole, formattedSelectedControllerMediaSubCategories, user])

  const [filtersState, setFiltersState] = useState({
    [SELECTED_SUB_CATEGORIES]: [],
    [FILE_STATUS]: [],
    [BRAND_CATEGORY]: [],
    [BUYER_FILTER]: [],
    ...automaticFilters
  })

  const {
    [SELECTED_SUB_CATEGORIES]: selectedSubCategories,
    [FILE_STATUS]: fileStatus,
    [BRAND_CATEGORY]: brandCategory,
    [BUYER_FILTER]: buyers
  } = filtersState

  const filterRequestParams = useMemo(() => {
    return {
      ...(selectedSubCategories.length && { media_sub_category: mapListForQuery(selectedSubCategories).join(',') }),
      // files approval statuses
      ...(fileStatus.length && { [FILES_APPROVAL_STATUS_FILTER]: mapListForQuery(fileStatus, 'value').join(',') }),

      // file statuses
      ...(fileStatus === STATUS_FILTER_AWAITING_FILES && {
        file_pending: true
      }),
      ...(fileStatus === STATUS_FILTER_FILES_PENDING_REVIEW && {
        files_pending_review: true
      }),
      ...(fileStatus === STATUS_FILTER_FILES_OVERDUE && {
        files_overdue: true
      }),

      // brand category
      ...(brandCategory.length && { brand_category: mapListForQuery(brandCategory).join(',') }),

      // buyer
      ...(buyers.length && { buyer: mapListForQuery(buyers, 'value').join(',') })
    }
  }, [fileStatus, selectedSubCategories, brandCategory, buyers])

  const installationReportFilterProps = useMemo(
    () => ({
      filterRequestParams,
      filtersState,
      setFiltersState
    }),
    [filterRequestParams, filtersState, setFiltersState]
  )
  // make a type check for the props
  PropTypes.checkPropTypes(propTypes, installationReportFilterProps, 'prop', 'useRequestHandler')

  useEffect(() => {
    onFiltersChange(filterRequestParams)
  }, [dispatch, filterRequestParams, onFiltersChange])

  return (
    <ApprovalsFiltersContext.Provider value={installationReportFilterProps}>
      {children}
    </ApprovalsFiltersContext.Provider>
  )
}

export const filterRequestParamsTypes = PropTypes.shape({
  media_sub_category: PropTypes.string,
  file_pending: PropTypes.bool,
  files_pending_review: PropTypes.bool,
  files_overdue: PropTypes.bool,
  files_approval_status: PropTypes.string,
  brand_category: PropTypes.string,
  buyer: PropTypes.string
}).isRequired

const propTypes = {
  filterRequestParams: filterRequestParamsTypes,
  filtersState: PropTypes.shape({
    [SELECTED_SUB_CATEGORIES]: PropTypes.array,
    [FILE_STATUS]: PropTypes.array,
    [BRAND_CATEGORY]: PropTypes.array,
    [BUYER_FILTER]: PropTypes.array
  }).isRequired,
  setFiltersState: PropTypes.func.isRequired
}

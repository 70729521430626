import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import ContentSection from '../../../../../../../../features/components/ContentSection'
import ContentRow from '../../../../../../../../features/components/ContentSection/ContentRow'
import RecipientsTabs from '../../../../../../../../features/components/notificationFormFields/RecipientsTabs'

import { mediaOrderSelector } from '../../../../../../../../modules/selectors/mediaOrders'

import { NOTIFICATION_USERS } from '../../../../../../../../features/components/notificationFormFields/fields'

import useStyles from './styles'

const UpdateMediaOrderRecipientsFormContent = ({ formik }) => {
  const classes = useStyles()

  const { [NOTIFICATION_USERS]: initialNotificationUsers = [] } = useSelector(mediaOrderSelector)

  const initialNotificationUsersIds = useMemo(
    () => initialNotificationUsers?.map(user => user.id),
    [initialNotificationUsers]
  )

  return (
    <ContentSection title="Add New Recipients">
      <ContentRow
        title="Confirmation Email Recipient(s)"
        description="Users or email addresses that will receive the confirmation email (including required file specifications if applicable)"
        rightSideClassName={classes.rightSide}
      >
        <RecipientsTabs formik={formik} selfAccountMembersIdsToFilter={initialNotificationUsersIds} />
      </ContentRow>
    </ContentSection>
  )
}

export default UpdateMediaOrderRecipientsFormContent

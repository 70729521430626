import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { v4 as uuidv4 } from 'uuid'

import Table from '../../../../../../../../../components/Table'
import Chip from '../../../../../../../../../components/Chip'
import RecipientActions from './RecipientActions'

import { useRecipientActions } from './useRecipientActions'

import {
  mediaOrderSelector,
  updateMediaOrderIsLoadingSelector
} from '../../../../../../../../../modules/selectors/mediaOrders'
import { CHIP_COLORS } from '../../../../../../../../../constants/other'
import {
  FIRST_NAME,
  NOTIFICATION_EMAILS,
  NOTIFICATION_USERS
} from '../../../../../../../../../features/components/notificationFormFields/fields'

import { phonesDownSize } from '../../../../../../../../../styles/const/breakpoints'
import useStyles from './styles'

export const tableColumnsSize = {
  name: 120,
  status: 65,
  actions: 30
}

const RecipientsTable = () => {
  const classes = useStyles()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const updateMediaOrderIsLoading = useSelector(updateMediaOrderIsLoadingSelector)
  const { [NOTIFICATION_EMAILS]: notificationEmails = [], [NOTIFICATION_USERS]: notificationUsers = [] } =
    useSelector(mediaOrderSelector)

  const { resendNotificationHandler, removeNotificationHandler, recipientUpdateRowId } = useRecipientActions()

  const recipientsColumns = useMemo(() => {
    return [
      {
        header: 'Name',
        Cell: ({ name }) => name,
        style: { maxWidth: tableColumnsSize.name, display: 'block', paddingLeft: 0, paddingRight: 6 }
      },
      {
        header: 'Email',
        Cell: ({ email }) => email
      },
      {
        header: 'Status',
        Cell: () => <Chip text="Sent" color={CHIP_COLORS.green} />,
        style: { maxWidth: tableColumnsSize.status, paddingRight: 0 }
      },
      {
        Cell: data => (
          <RecipientActions
            data={data}
            onRemoveNotification={removeNotificationHandler}
            onResendNotification={resendNotificationHandler}
            // disable all actions when any of the recipients is being updated or adding new one
            isDisabled={updateMediaOrderIsLoading}
          />
        ),
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ]
  }, [isMobile, removeNotificationHandler, resendNotificationHandler, updateMediaOrderIsLoading])

  const combinedRecipients = useMemo(() => {
    const formattedNotificationEmails = notificationEmails.map(email => ({
      name: email[FIRST_NAME],
      email: email.email
    }))

    const formattedNotificationUsers = notificationUsers.map(user => ({
      userId: user.id,
      name: user.full_name,
      email: user.email
    }))

    return [...formattedNotificationEmails, ...formattedNotificationUsers].map(recipientItem => ({
      ...recipientItem,
      // we add id to each recipient item to be able to track loading state of each item
      id: uuidv4()
    }))
  }, [notificationEmails, notificationUsers])

  return (
    <Table
      data={combinedRecipients}
      cols={recipientsColumns}
      className={classes.recipientsTable}
      hideFooterRow
      itemUpdatingId={recipientUpdateRowId}
    />
  )
}

export default RecipientsTable

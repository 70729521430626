import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import ActionsButtonsList from '../ActionsButtonsList'
import ActionsDropdown from '../ActionsDropdown'

import { phonesDownSize } from '../../../styles/const/breakpoints'

const AdaptiveActions = ({ itemId, options = [], isDisabled }) => {
  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  return isMobile ? (
    <ActionsButtonsList itemId={itemId} options={options.length ? options : null} isDisabled={isDisabled} />
  ) : (
    <ActionsDropdown
      itemId={itemId}
      options={options.length ? options : null}
      isDisabled={isDisabled}
      closeOnInsideClick
    />
  )
}

AdaptiveActions.propTypes = {
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string,
      isLinkExternal: PropTypes.bool,
      onClickHandler: PropTypes.func
    })
  ),
  isDisabled: PropTypes.bool
}

export default AdaptiveActions

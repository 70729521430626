import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApprovalsFilters from './ApprovalsFilters'
import InstallationReportTable from '../InstallationReport/InstallationReportTable'

import {
  clearGetInstallationBookedMediaReport,
  getInstallationBookedMediaReport
} from '../../../../../modules/actions/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import { FILES_APPROVAL_STATUS_FILTER } from './ApprovalsFilters/ApprovalsFiltersContext'

const Approvals = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [hasSelectedFileFilter, setHasSelectedFileFilter] = useState(false)
  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const handleFilterChange = useCallback(
    filters => {
      const hasFileFilter = !!filters[FILES_APPROVAL_STATUS_FILTER]
      setHasSelectedFileFilter(!!filters[FILES_APPROVAL_STATUS_FILTER])

      if (hasFileFilter) {
        // due to performance issues to get all data without file filter we allow to fetch data only when some
        // file filter is applied.

        dispatch(
          getInstallationBookedMediaReport({
            controller: selectedControllerId,
            ...filters
          })
        )
      } else {
        dispatch(clearGetInstallationBookedMediaReport())
      }
    },
    [dispatch, selectedControllerId]
  )

  const noDataText = useMemo(() => {
    return hasSelectedFileFilter
      ? t('There are no files to be approved with the applied filters')
      : t('Please apply a File Status filter to view the approvals list')
  }, [hasSelectedFileFilter, t])

  return (
    <>
      <ApprovalsFilters onFiltersChange={handleFilterChange} />
      <br />
      {/* when no file filter added, the table should show the no data message */}
      <InstallationReportTable noDataContentText={noDataText} showNoData={!hasSelectedFileFilter} />
    </>
  )
}

export default Approvals

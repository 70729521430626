import { createStyles } from '../../../../../../../../styles/helpers'

const useStyles = createStyles({
  typeField: {
    minWidth: 140,
    maxWidth: 140
  },
  fieldsSeparator: {
    alignSelf: 'center'
  },
  valueFieldWithSymbol: {
    '& input': {
      paddingLeft: 34
    }
  },
  valueField: {
    flexGrow: 1
  }
})

export default useStyles

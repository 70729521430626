import React, { useMemo } from 'react'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import FileDimensionsFields from './FileDimensionsFields'
import MultiRowFields from '../../../../../../../features/components/Form/MultiRowFields'

import {
  FILE_DIMENSIONS_PAIRS,
  FILES_REQUIREMENTS_HEIGHT,
  FILES_REQUIREMENTS_WIDTH
} from '../../../../MediaProductCreate/MediaProductCreateForm/fields'

import useStyles from './styles'

const initialValueTemplate = {
  [FILES_REQUIREMENTS_WIDTH]: '',
  [FILES_REQUIREMENTS_HEIGHT]: ''
}

const FileDimensions = ({ formik, filePath, isPdfOnly }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const { values } = formik

  const mainValueName = `${filePath}.${FILE_DIMENSIONS_PAIRS}`

  const fileSpecifications = getIn(values, mainValueName)

  const fieldsComponentProps = useMemo(
    () => ({
      filePath,
      isPdfOnly
    }),
    [filePath, isPdfOnly]
  )

  const addNewRowText = useMemo(
    () => (fileSpecifications.length === 0 ? t('+ Add dimension') : t('+ Add another dimension')),
    [t, fileSpecifications]
  )

  return (
    <MultiRowFields
      rowClassName={classes.fileSpecificationsRow}
      mainValueName={mainValueName}
      subValueNames={[FILES_REQUIREMENTS_WIDTH, FILES_REQUIREMENTS_HEIGHT]}
      initialValueTemplate={initialValueTemplate}
      formik={formik}
      FieldsComponent={FileDimensionsFields}
      fieldsComponentProps={fieldsComponentProps}
      addNewRowText={addNewRowText}
      // min rows is 0 because we allow to delete all specifications
      minRows={0}
    />
  )
}

FileDimensions.propTypes = {
  formik: PropTypes.object.isRequired,
  filePath: PropTypes.string.isRequired
}

export default FileDimensions

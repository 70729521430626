import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

export const selectorPlaceholder = () => {}

// this hook helps to process all important data fetching processes i.e.:
// create initial request for requestHandler
// clear data on success or error request made

export default function useRequestHandler(props) {
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'useRequestHandler')
  const {
    requestHandler,
    clearRequestAction,
    makeInitialRequest = false,
    isLoadingRequestSelector = selectorPlaceholder,
    successRequestSelector = selectorPlaceholder,
    errorRequestSelector = selectorPlaceholder,
    clearOnError = true,
    clearOnSuccess = true,
    clearOnUmount = true
  } = props
  const dispatch = useDispatch()

  const isLoading = useSelector(isLoadingRequestSelector)
  const requestSuccess = useSelector(successRequestSelector)
  const requestError = useSelector(errorRequestSelector)

  useEffect(() => {
    // initial data request on component mount
    if (makeInitialRequest) {
      requestHandler()
    }
  }, [dispatch, makeInitialRequest, requestHandler])

  useEffect(() => {
    // clear after success
    // usually this is needed for manual POST, PUT, DELETE requests which are not handled in ProgressButton or by some form flow
    if (clearOnSuccess && requestSuccess) {
      dispatch(clearRequestAction())
    }
  }, [dispatch, clearRequestAction, clearOnSuccess, requestSuccess])

  useEffect(() => {
    // clear after error
    if (clearOnError && requestError) {
      dispatch(clearRequestAction())
    }
  }, [dispatch, clearRequestAction, clearOnError, requestError])

  useEffect(
    () => () => {
      // clear data on component unmount if it was made but not cleared
      clearOnUmount && dispatch(clearRequestAction())
    },
    [dispatch, clearRequestAction, clearOnUmount]
  )

  return useMemo(
    () => ({
      isLoading,
      requestSuccess,
      requestError
    }),
    [isLoading, requestSuccess, requestError]
  )
}

const propTypes = {
  requestHandler: PropTypes.func,
  clearRequestAction: PropTypes.func.isRequired,
  makeInitialRequest: PropTypes.bool,
  isLoadingRequestSelector: PropTypes.func,
  successRequestSelector: PropTypes.func,
  errorRequestSelector: PropTypes.func,
  clearOnError: PropTypes.bool,
  clearOnSuccess: PropTypes.bool
}

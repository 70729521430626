import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  mediaOrderDigitalProductsSelector,
  mediaOrderOOHProductsSelector,
  mediaOrderSelector
} from '../../../modules/selectors/mediaOrders'

export function useManageTabsRepresentation() {
  const mediaOrder = useSelector(mediaOrderSelector)
  const digitalProducts = useSelector(mediaOrderDigitalProductsSelector)
  const oohProducts = useSelector(mediaOrderOOHProductsSelector)

  const mediaOrderHasFiles = !!mediaOrder?.has_required_files

  return useMemo(() => {
    let showOnsiteDigital = false
    let showOffNetworkDigital = false

    digitalProducts.forEach(product => {
      if (product.offsite === false) showOnsiteDigital = true
      if (product.offsite === true) showOffNetworkDigital = true
    })

    return {
      showDigitalScreensTab: !!oohProducts.length,
      showOnsiteDigital,
      showOffNetworkDigital,
      showRequestedFilesTab: mediaOrderHasFiles
    }
  }, [oohProducts, digitalProducts, mediaOrderHasFiles])
}

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useRequestHandler, { selectorPlaceholder } from '../../../../../../../../../hooks/useRequestHandler'

import { showToasts } from '../../../../../../../../../helpers/toasts'
import {
  clearMediaOrderResendConfirmationEmail,
  clearUpdateMediaOrder,
  mediaOrderResendConfirmationEmail,
  updateMediaOrder
} from '../../../../../../../../../modules/actions/mediaOrders'

import {
  NOTIFICATION_EMAILS,
  NOTIFICATION_USERS
} from '../../../../../../../../../features/components/notificationFormFields/fields'
import {
  mediaOrderResendConfirmationEmailIsLoadingSelector,
  mediaOrderResendConfirmationEmailWasResentSelector,
  mediaOrderSelector,
  updateMediaOrderIsLoadingSelector,
  updateMediaOrderErrorSelector,
  updateMediaOrderWasUpdatedSelector
} from '../../../../../../../../../modules/selectors/mediaOrders'
import { TOAST_TYPE } from '../../../../../../../../../constants/other'

export function useRecipientActions() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [recipientUpdateRowId, setRecipientUpdateRowId] = useState(null)

  const {
    id: mediaOrderId,
    controller,
    [NOTIFICATION_EMAILS]: notificationEmails = [],
    [NOTIFICATION_USERS]: notificationUsers = []
  } = useSelector(mediaOrderSelector)
  const mediaOrderResendConfirmationEmailWasResent = useSelector(mediaOrderResendConfirmationEmailWasResentSelector)
  const mediaOrderResendConfirmationEmailIsLoading = useSelector(mediaOrderResendConfirmationEmailIsLoadingSelector)

  // manage removing recipient only when the update process is started, to avoid triggering the actions for
  // different media order updates(for example payment status or adding new recipients)
  const { isLoading: updateMediaOrderIsLoading } = useRequestHandler({
    clearRequestAction: clearUpdateMediaOrder,
    isLoadingRequestSelector: recipientUpdateRowId ? updateMediaOrderIsLoadingSelector : selectorPlaceholder,
    successRequestSelector: recipientUpdateRowId ? updateMediaOrderWasUpdatedSelector : selectorPlaceholder,
    errorRequestSelector: recipientUpdateRowId ? updateMediaOrderErrorSelector : selectorPlaceholder
  })

  const resendNotificationHandler = useCallback(
    ({ userId, email, recipientRowId }) => {
      let requestData = {}
      if (userId) {
        requestData = {
          [NOTIFICATION_USERS]: [userId]
        }
      } else {
        requestData = {
          [NOTIFICATION_EMAILS]: [email]
        }
      }

      setRecipientUpdateRowId(recipientRowId)
      dispatch(mediaOrderResendConfirmationEmail(mediaOrderId, requestData, recipientRowId))
    },
    [dispatch, mediaOrderId]
  )

  const removeNotificationHandler = useCallback(
    ({ userId, email, recipientRowId }) => {
      let requestData = {}
      if (userId) {
        requestData = {
          // remove user
          [NOTIFICATION_USERS]: notificationUsers.filter(user => user.id !== userId).map(user => user.id)
        }
      } else {
        requestData = {
          [NOTIFICATION_EMAILS]: notificationEmails
            .filter(emailContact => emailContact.email !== email)
            .map(emailContact => emailContact.email)
        }
      }

      const data = {
        ...requestData,
        controller: controller.id
      }

      setRecipientUpdateRowId(recipientRowId)
      dispatch(updateMediaOrder(mediaOrderId, data))
    },
    [controller, dispatch, mediaOrderId, notificationEmails, notificationUsers]
  )

  useEffect(() => {
    if (mediaOrderResendConfirmationEmailWasResent) {
      showToasts({
        type: TOAST_TYPE.success,
        message: t('Booking confirmation email sent successfully')
      })
      dispatch(clearMediaOrderResendConfirmationEmail())
    }
  }, [dispatch, mediaOrderResendConfirmationEmailWasResent, t])

  useEffect(() => {
    // if the recipient was updated or failed, remove the row loading state
    if (recipientUpdateRowId && !mediaOrderResendConfirmationEmailIsLoading && !updateMediaOrderIsLoading) {
      setRecipientUpdateRowId(null)
    }
  }, [mediaOrderResendConfirmationEmailIsLoading, recipientUpdateRowId, updateMediaOrderIsLoading])

  return {
    resendNotificationHandler,
    removeNotificationHandler,
    recipientUpdateRowId
  }
}

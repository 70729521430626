import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AdaptiveActions from '../../../../../../../../../../features/components/AdaptiveActions'

const RecipientActions = ({ data, onResendNotification, onRemoveNotification, isDisabled }) => {
  const { t } = useTranslation()

  const { userId, email, id: recipientRowId } = data

  const resendNotificationHandler = useCallback(() => {
    onResendNotification({ userId, email, recipientRowId })
  }, [onResendNotification, userId, email, recipientRowId])

  const removeNotificationHandler = useCallback(() => {
    onRemoveNotification({ userId, email, recipientRowId })
  }, [email, onRemoveNotification, recipientRowId, userId])

  const dropdownOptions = useMemo(
    () => [
      {
        text: t('Resend'),
        onClickHandler: resendNotificationHandler
      },
      {
        text: t('Remove'),
        onClickHandler: removeNotificationHandler
      }
    ],
    [removeNotificationHandler, resendNotificationHandler, t]
  )

  return <AdaptiveActions options={dropdownOptions} isDisabled={isDisabled} />
}

RecipientActions.propTypes = {
  data: PropTypes.shape({
    userId: PropTypes.number,
    email: PropTypes.string,
    id: PropTypes.string.isRequired
  }).isRequired,
  onRemoveNotification: PropTypes.func.isRequired,
  onResendNotification: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
}

export default RecipientActions

import {
  addDays,
  addMonths,
  differenceInCalendarMonths,
  differenceInDays,
  endOfDay,
  endOfMonth,
  getDaysInMonth,
  isFirstDayOfMonth,
  startOfMonth,
  subDays,
  subMonths
} from 'date-fns'
import { t } from 'i18next'

import { calculateMonthsInPx, getSubtitleMonthBasedCols, getYearTitleCols } from './year'
import {
  calculateCalendarNextWeekDate,
  calculateCalendarPrevWeekDate,
  calculateWeeksInPx,
  getQuarterSubtitleCols,
  getQuarterTitleCols
} from './quarter'
import { getMonthTitleCols, getSubtitleDaysColumns } from './month'

import { CALENDAR_VIEW_TYPE } from '../../../../constants/selectLists/calendarList'
import { calc } from '../../../../helpers/numbers'
import { EVENTS_AREA_PADDING, ONE_DAY } from '../../../../constants/timeline'

export const diffInDaysDecimal = (end_timestamp, start_timestamp) => {
  return calc(end_timestamp).minus(start_timestamp).div(ONE_DAY).toNumber()
}

export const getTitleCols = (viewType, startDate, endDate, monthCount) => {
  switch (viewType) {
    case CALENDAR_VIEW_TYPE.YEAR:
    case CALENDAR_VIEW_TYPE.HALF_YEAR:
      return getYearTitleCols(startDate, endDate, monthCount)
    case CALENDAR_VIEW_TYPE.QUARTER:
      return getQuarterTitleCols(startDate, endDate)
    case CALENDAR_VIEW_TYPE.MONTH:
      return getMonthTitleCols(startDate, endDate)
    default:
      throw new Error(t('unsupportedViewType', { viewType }))
  }
}

export const getSubtitleCols = (viewType, startDate, endDate) => {
  switch (viewType) {
    case CALENDAR_VIEW_TYPE.YEAR:
      return getSubtitleMonthBasedCols(startDate, 12)
    case CALENDAR_VIEW_TYPE.HALF_YEAR:
      return getSubtitleMonthBasedCols(startDate, 6)
    case CALENDAR_VIEW_TYPE.QUARTER:
      return getQuarterSubtitleCols(startDate, endDate)
    case CALENDAR_VIEW_TYPE.MONTH:
      return getSubtitleDaysColumns(startDate, endDate)
    default:
      throw new Error(t('unsupportedViewType', { viewType }))
  }
}

export const getInitialDateRange = viewType => {
  // all initial periods starts on the first day of the current month
  const startDate = startOfMonth(new Date())
  switch (viewType) {
    case CALENDAR_VIEW_TYPE.YEAR:
      return {
        startDate: startDate,
        // show 12 months
        endDate: endOfMonth(addMonths(startDate, 11))
      }

    case CALENDAR_VIEW_TYPE.QUARTER:
      return {
        startDate: startDate,
        // show 3 months
        endDate: endOfMonth(addMonths(startDate, 2))
      }

    case CALENDAR_VIEW_TYPE.MONTH:
      return {
        startDate: startDate,
        // show 1 month
        endDate: endOfMonth(startDate)
      }

    default:
      throw new Error(t('unsupportedViewType', { viewType }))
  }
}

// This function will calculate date range based on start date -> for example one year from 21.10.2021 will be 21.10.2022
const calculateDateRange = (viewType, startDate, type) => {
  switch (viewType) {
    case CALENDAR_VIEW_TYPE.YEAR:
      return {
        startDate: startDate,
        endDate: endOfMonth(addMonths(startDate, 11))
      }

    case CALENDAR_VIEW_TYPE.QUARTER:
      return {
        startDate: startDate,
        // show 3 months
        endDate: endOfMonth(addMonths(startDate, 2))
      }

    case CALENDAR_VIEW_TYPE.MONTH:
      return calculateMonthDateRange(startDate, type)

    default:
      throw new Error(t('unsupportedViewType', { viewType }))
  }
}

export const calculateMonthDateRange = (startDate, type) => {
  if (isFirstDayOfMonth(startDate)) {
    // return period of 1 month which starts from 01
    return {
      startDate,
      endDate: endOfMonth(startDate)
    }
  } else {
    // return period of 1 month which starts in one month and ends in the next one
    switch (type) {
      case 'initial':
        const daysCount = getDaysInMonth(startDate)
        return {
          startDate,
          endDate: addDays(startDate, daysCount - 1)
        }
      case 'next':
        const prevDay = subDays(startDate, 1)
        const prevDaysCount = getDaysInMonth(prevDay)
        return {
          startDate,
          endDate: addDays(startDate, prevDaysCount - 1)
        }
      case 'prev':
        const nextDay = addDays(startDate, 1)
        const nextDaysCount = getDaysInMonth(nextDay)
        return {
          startDate,
          endDate: addDays(startDate, nextDaysCount - 1)
        }

      default:
        throw new Error(t('unsupported type'))
    }
  }
}

export const getNextDateRange = (viewType, startDate, endDate) => {
  switch (viewType) {
    case CALENDAR_VIEW_TYPE.YEAR:
      // switch to next 3 months
      return calculateDateRange(viewType, addMonths(startDate, 3))
    case CALENDAR_VIEW_TYPE.QUARTER:
      // switch to next month
      return calculateDateRange(viewType, addMonths(startDate, 1))

    case CALENDAR_VIEW_TYPE.MONTH:
      // switch to next week
      return {
        startDate: calculateCalendarNextWeekDate(startDate, 'start', true),
        endDate: calculateCalendarNextWeekDate(endDate, 'end', true)
      }
    default:
      throw new Error(t('unsupportedViewType', { viewType }))
  }
}

export const getPrevDateRange = (viewType, startDate, endDate) => {
  switch (viewType) {
    case CALENDAR_VIEW_TYPE.YEAR:
      return calculateDateRange(viewType, subMonths(startDate, 3))
    case CALENDAR_VIEW_TYPE.QUARTER:
      return calculateDateRange(viewType, subMonths(startDate, 1))
    case CALENDAR_VIEW_TYPE.MONTH:
      return {
        startDate: calculateCalendarPrevWeekDate(startDate, 'start', true),
        endDate: calculateCalendarPrevWeekDate(endDate, 'end', true)
      }
    default:
      throw new Error(t('unsupportedViewType', { viewType }))
  }
}

export const getTimeInPx = (viewType, tableInnerWidth, startDate, endDate) => {
  switch (viewType) {
    case CALENDAR_VIEW_TYPE.YEAR:
    case CALENDAR_VIEW_TYPE.HALF_YEAR: {
      const monthsCount = differenceInCalendarMonths(endDate, startDate) + 1
      return calculateMonthsInPx({
        intervalsCount: monthsCount,
        viewType,
        tableInnerWidth,
        startDate,
        endDate
      })
    }

    case CALENDAR_VIEW_TYPE.QUARTER: {
      return calculateWeeksInPx({ viewType, tableInnerWidth, startDate, endDate })
    }

    case CALENDAR_VIEW_TYPE.MONTH: {
      // +1 is to include the last day in the calculation
      const daysCount = differenceInDays(endDate, startDate) + 1
      const timeToPx = [...Array(daysCount)].map((_, index) => {
        const start = addDays(startDate, index)
        const end = endOfDay(start)

        return {
          oneDayInPx: tableInnerWidth / daysCount,
          daysCount: 1,
          start: start.getTime(),
          end: end.getTime()
        }
      })

      // first and last intervals have padding in columns when the event ends or starts on the edge
      timeToPx[0].oneDayInPx -= EVENTS_AREA_PADDING
      timeToPx[timeToPx.length - 1].oneDayInPx -= EVENTS_AREA_PADDING

      return timeToPx
    }
    default:
      throw new Error(t('unsupportedViewType', { viewType }))
  }
}

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Dropdown from '../../../components/Dropdown'
import InternalLink from '../../../components/InternalLink'

import { ReactComponent as DotsIcon } from '../../../assets/icons/dots-menu.svg'

import useStyles from './style'

const ActionsDropdown = React.memo(({ itemId, options, callbackData, className, closeOnInsideClick, isDisabled }) => {
  const classes = useStyles(options)

  const { t } = useTranslation()

  if (!options || options?.length === 0) {
    // don't show the dropdown if there are no options
    return null
  }

  return (
    <div className={classnames(className, classes.dropdownWrapper)} data-html2canvas-ignore>
      <Dropdown
        className={classes.dropdown}
        placement="bottom-end"
        isDisabled={isDisabled}
        triggerElement={
          <button type="button" className={classes.actionsBtn}>
            <DotsIcon />
          </button>
        }
        closeOnInsideClick={closeOnInsideClick}
      >
        <div className={classes.actionsDropdown}>
          <ul className={classes.actionsList}>
            {options &&
              options.map(option =>
                option.link ? (
                  <Fragment key={option.text}>
                    {option.isLinkExternal ? (
                      <a
                        href={option.link}
                        target="_blank"
                        rel="noreferrer nofollow"
                        aria-disabled={true}
                        className={classnames(classes.actionsLink, classes.actionsItem)}
                      >
                        {t(option.text)}
                      </a>
                    ) : (
                      <InternalLink className={classnames(classes.actionsItem, classes.actionsLink)} to={option.link}>
                        {t(option.text)}
                      </InternalLink>
                    )}
                  </Fragment>
                ) : (
                  <li
                    className={classes.actionsItem}
                    key={option.text}
                    onClick={e => option.onClickHandler(e, itemId, callbackData)}
                  >
                    {t(option.text)}
                  </li>
                )
              )}
          </ul>
        </div>
      </Dropdown>
    </div>
  )
})

ActionsDropdown.propTypes = {
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  callbackData: PropTypes.object,
  className: PropTypes.string,
  closeOnInsideClick: PropTypes.bool,
  isDisabled: PropTypes.bool
}

export default ActionsDropdown

import React, { useCallback, useEffect, useMemo } from 'react'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import FieldRow from '../../../../../../features/components/Form/FieldsSection/FieldRow'
import Field from '../../../../../../components/Form/Field'
import MultiSelectBox from '../../../../../../features/components/Form/MultiSelectBox'
import Checkbox from '../../../../../../components/Form/Checkbox'
import FileDimensions from './FileDimensions'
import FileSpecifications from './FileSpecifications'
import MinimisedSectionNew from '../../../../../../components/MinimisedSectionNew'
import MinimisedSectionHeader from '../../../../../../components/MinimisedSectionNew/MinimisedSectionHeader'

import { checkIsPdfOnly } from '../../../../MediaOrderForms/UploadBookedMediaFiles/UploadBookedMediaFilesForm/helpers'
import { getSupportedFormats, productFileTypes } from '../../../../../../constants/product'

import {
  FILES_REQUIREMENTS_DESCRIPTION,
  FILES_REQUIREMENTS_NAME,
  FILES_REQUIREMENTS_REQUIRED,
  FILES_REQUIREMENTS_SUPPORTED_FORMATS,
  FILES_REQUIREMENTS_TYPE
} from '../../../MediaProductCreate/MediaProductCreateForm/fields'
import { LOCATION, TRIGGER_BOOKING_NOTIFICATION } from '../../../fields'

import useStyles from './styles'

const FilesRequirementsSection = ({ formik, itemPath, selected, onSelect, onClose, onDelete, index }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { values, setFieldValue } = formik

  const fileTypeValue = getIn(values, `${itemPath}.${FILES_REQUIREMENTS_TYPE}`)
  const fileRequiredValue = getIn(values, `${itemPath}.${FILES_REQUIREMENTS_REQUIRED}`)
  const fileSupportedTypeValue = getIn(values, `${itemPath}.${FILES_REQUIREMENTS_SUPPORTED_FORMATS}`)
  const triggerBNFChecked = getIn(values, `${itemPath}.${TRIGGER_BOOKING_NOTIFICATION}`)

  const supportedFormats = useMemo(() => getSupportedFormats(fileTypeValue), [fileTypeValue])

  const onClickHandler = useCallback(() => {
    if (selected) {
      onClose()
    } else {
      onSelect()
    }
  }, [selected, onSelect, onClose])

  // Hide this field if there is no location selected
  const showTriggerBookingNotification = !!values[LOCATION]

  const selectedFileFormats = getIn(values, `${itemPath}.${FILES_REQUIREMENTS_SUPPORTED_FORMATS}`)

  const isPdfOnly = useMemo(() => {
    const selectedFileFormatsArray = selectedFileFormats.map(format => format.value)
    return checkIsPdfOnly(selectedFileFormatsArray)
  }, [selectedFileFormats])

  useEffect(() => {
    // Clear triggerBNF checkbox when it's hidden
    if (!showTriggerBookingNotification && triggerBNFChecked) {
      setFieldValue(`${itemPath}.${TRIGGER_BOOKING_NOTIFICATION}`, false)
    }
  }, [setFieldValue, showTriggerBookingNotification, triggerBNFChecked, itemPath])

  return (
    <MinimisedSectionNew
      headerContent={
        <MinimisedSectionHeader title={`File ${index + 1}`} onDelete={onDelete} deleteBtnText="Remove file" />
      }
      className={classes.fileSection}
      isOpened={selected}
      onHeaderClick={onClickHandler}
    >
      <FieldRow title="Name" description="The requested asset." rightSideClassName={classes.rightSide}>
        <Field formik={formik} name={`${itemPath}.${FILES_REQUIREMENTS_NAME}`} placeholder={t('Name')} />
      </FieldRow>
      <FieldRow
        title="Description"
        description="A description of the requested file."
        rightSideClassName={classes.rightSide}
      >
        <Field
          formik={formik}
          name={FILES_REQUIREMENTS_DESCRIPTION}
          label="Description"
          autoComplete="off"
          placeholder="Description"
        />
      </FieldRow>

      <FieldRow
        title="File type"
        description="Image, video, text or other file."
        rightSideClassName={classes.rightSide}
      >
        <Field
          formik={formik}
          name={`${itemPath}.${FILES_REQUIREMENTS_TYPE}`}
          value={fileTypeValue}
          placeholder={'File type'}
          options={productFileTypes}
        />
      </FieldRow>

      <FieldRow
        title="Support file formats"
        description="We will validate that the file is uploaded in one of these selected formats."
        rightSideClassName={classes.rightSide}
      >
        <MultiSelectBox
          formik={formik}
          name={`${itemPath}.${FILES_REQUIREMENTS_SUPPORTED_FORMATS}`}
          value={fileSupportedTypeValue}
          setFieldValue={setFieldValue}
          placeholder={t('Supported File Types')}
          options={supportedFormats}
        />
      </FieldRow>

      <FieldRow
        title="Required"
        description="If checked, this field will be marked as required."
        rightSideClassName={classes.rightSide}
      >
        <Checkbox
          className={classes.checkboxRequired}
          id={`${itemPath}.${FILES_REQUIREMENTS_REQUIRED}`}
          title={t('Required file')}
          checked={fileRequiredValue}
          onCheck={() => setFieldValue(`${itemPath}.${FILES_REQUIREMENTS_REQUIRED}`, !fileRequiredValue)}
        />
      </FieldRow>

      <FieldRow title="Dimensions" rightSideClassName={classes.rightSide}>
        <FileDimensions formik={formik} filePath={itemPath} isPdfOnly={isPdfOnly} />
      </FieldRow>
      <FieldRow title="Other Specifications" rightSideClassName={classes.rightSide}>
        <FileSpecifications formik={formik} filePath={itemPath} isPdfOnly={isPdfOnly} />
      </FieldRow>
      {showTriggerBookingNotification && (
        <FieldRow
          title="Booking Notification"
          description="If checked, upon approval of this file a booking notification will be sent to the linked location’s booking notification recipients"
          rightSideClassName={classes.rightSide}
        >
          <Checkbox
            id={`${itemPath}.${TRIGGER_BOOKING_NOTIFICATION}`}
            title={t('Trigger booking notification')}
            checked={triggerBNFChecked}
            onCheck={() => setFieldValue(`${itemPath}.${TRIGGER_BOOKING_NOTIFICATION}`, !triggerBNFChecked)}
          />
        </FieldRow>
      )}
    </MinimisedSectionNew>
  )
}

FilesRequirementsSection.propTypes = {
  formik: PropTypes.object.isRequired,
  itemPath: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
}

export default FilesRequirementsSection

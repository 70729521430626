import React, { useMemo } from 'react'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import MultiRowFields from '../../../../../../../features/components/Form/MultiRowFields'
import FileSpecificationFields from './FileSpecificationsFields'

import {
  FILE_SPECIFICATION_TYPE,
  FILE_SPECIFICATION_VALUE,
  FILE_SPECIFICATIONS_PAIRS
} from '../../../../MediaProductCreate/MediaProductCreateForm/fields'

import { specificationsList } from './FileSpecificationsFields/specificationsList'

import useStyles from './styles'

const initialValueTemplate = {
  [FILE_SPECIFICATION_TYPE]: '',
  [FILE_SPECIFICATION_VALUE]: ''
}

const FileSpecifications = ({ formik, filePath, isPdfOnly }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const { values } = formik

  const mainValueName = `${filePath}.${FILE_SPECIFICATIONS_PAIRS}`

  const fileSpecifications = getIn(values, mainValueName)

  const allowAddMore = useMemo(() => {
    // show add not all specifications are already added
    return specificationsList.length > fileSpecifications.length
  }, [fileSpecifications])

  const fieldsComponentProps = useMemo(
    () => ({
      filePath,
      isPdfOnly
    }),
    [filePath, isPdfOnly]
  )

  const addNewRowText = useMemo(
    () => (fileSpecifications.length === 0 ? t('+ Add specification') : t('+ Add another specification')),
    [t, fileSpecifications]
  )

  return (
    <MultiRowFields
      rowClassName={classes.fileSpecificationsRow}
      mainValueName={mainValueName}
      subValueNames={[FILE_SPECIFICATION_TYPE, FILE_SPECIFICATION_VALUE]}
      initialValueTemplate={initialValueTemplate}
      formik={formik}
      FieldsComponent={FileSpecificationFields}
      fieldsComponentProps={fieldsComponentProps}
      addNewRowText={addNewRowText}
      // min rows is 0 because we allow to delete all specifications
      minRows={0}
      allowAddMore={allowAddMore}
    />
  )
}

FileSpecifications.propTypes = {
  formik: PropTypes.object.isRequired,
  filePath: PropTypes.string.isRequired
}

export default FileSpecifications

import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import TableDataLoader from '../../../../../../components/Table/TableDataLoader'
import Table from '../../../../../../components/Table'

import { useRefreshInstallationReportRowStatusOnFileStatusUpdate } from './hooks'

import { useInstallationColumns } from '../columns'

import { clearControllerMembers, getControllerMembers } from '../../../../../../modules/actions/controller'
import {
  bulkUpdateBookedMediaSelector,
  bulkUpdateBookingMediaFileSelector,
  getInstallationBookedMediaReportErrorSelector,
  getInstallationBookedMediaReportIsLoadingSelector,
  installationBookedMediaReportSelector,
  installationBookedMediaReportWasLoadedSelector
} from '../../../../../../modules/selectors/mediaOrdersBookings'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'
import { controllerMembersWasLoadedSelector } from '../../../../../../modules/selectors/controller'
import { clearBulkUpdateBookedMedia } from '../../../../../../modules/actions/mediaOrdersBookings'

import { ExpandableRowsOpenStatesContext, ExpandableRowsOpenStatesProvider } from './ExpandableRowsOpenStatesContext'

import useStyles from './styles'
import useCommonStyles from '../../../../../../styles/common/table'

const InstallationReportTable = ({ noDataContentText = 'There are no bookings to show', showNoData = false }) => {
  const dispatch = useDispatch()

  const { booked_media: bookedMediaId } = useSelector(bulkUpdateBookingMediaFileSelector)
  const { ids: bulkUpdateBookedMediaIds, wasUpdated: bulkUpdateBookedMediaWasUpdated } =
    useSelector(bulkUpdateBookedMediaSelector)

  const controllerMembersWasLoaded = useSelector(controllerMembersWasLoadedSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const installationBookedMediaReport = useSelector(installationBookedMediaReportSelector)

  const isInstallationReportRowRefreshing = useRefreshInstallationReportRowStatusOnFileStatusUpdate()

  const maxUploadedFilesAmount = useMemo(() => {
    return installationBookedMediaReport.reduce((max, item) => {
      if (item.uploaded_files.length > max) {
        return item.uploaded_files.length
      }
      return max
    }, 0)
  }, [installationBookedMediaReport])

  const formattedInstallationBookedMediaReport = useMemo(() => {
    return installationBookedMediaReport.map(item => {
      if (item.id) {
        return item
      } else {
        return {
          ...item,
          // Add id to group rows to be able to represent update/loading state
          // group row doesn't have its own id, so we join sequential ids of the group
          id: item.sequential_ids?.join('_'),
          // copy booking id from first sequential row to parent product group row to be able to show it
          booking: item.sequential_list?.[0]?.booking
        }
      }
    })
  }, [installationBookedMediaReport])

  const commonClasses = useCommonStyles()
  const { t } = useTranslation()

  const itemUpdatingId = useMemo(() => {
    if (isInstallationReportRowRefreshing) {
      const parentRowWithSequentialIds = formattedInstallationBookedMediaReport.find(item =>
        // find parent fow of updating sub row
        item.sequential_ids?.includes(bookedMediaId)
      )
      if (parentRowWithSequentialIds) {
        // if we update sub row - show loading on parent row
        return parentRowWithSequentialIds.id
      }
      return bookedMediaId
    } else {
      return undefined
    }
  }, [isInstallationReportRowRefreshing, bookedMediaId, formattedInstallationBookedMediaReport])

  const rowsLoadingIds = useMemo(() => {
    // In InstallationReportTable we may have expandable rows which have nested sub rows
    // When user update status of main row - it means we update status of all sub rows
    // also user has ability to update status of sub rows separately
    // main row doesn't have its own id, so we added it manually by joining sequential ids,
    // so id of main row looks like this: "3956_3958_3957"

    const parentRow = formattedInstallationBookedMediaReport.find(item => {
      // find parent row of currently updating row
      return item.sequential_list?.some(subItem => bulkUpdateBookedMediaIds?.includes(subItem.id))
    })

    if (parentRow) {
      // if parentRow exists - check if all sub rows are updating
      const isParentRowLoading = parentRow.sequential_ids.every(id => bulkUpdateBookedMediaIds.includes(id))
      if (isParentRowLoading) {
        // if all sub rows are updating - show loading on parent row as well
        return [...parentRow.sequential_ids, parentRow.id]
      } else {
        // if not all sub rows are updating - show loading only on sub rows
        return bulkUpdateBookedMediaIds
      }
    } else {
      // if parentRow doesn't exist - it means we update regular row
      return bulkUpdateBookedMediaIds
    }
  }, [bulkUpdateBookedMediaIds, formattedInstallationBookedMediaReport])

  const classes = useStyles()

  const installationColumns = useInstallationColumns(maxUploadedFilesAmount)

  const rowTemplate = useMemo(() => {
    return {
      cols: installationColumns,
      params: {
        isExpandable: data => !!data?.sequential_list,
        renderExpandedContent: data => {
          return (
            <Table
              data={data?.sequential_list}
              cols={installationColumns}
              hideFooterRow
              hideHeadlineRow
              className={classes.subTable}
              rowTemplate={{
                rowClassName: classes.subTableRow
              }}
              rowLoadingIds={rowsLoadingIds}
            />
          )
        }
      },
      rowClassName: classes.row
    }
  }, [classes.subTable, classes.subTableRow, classes.row, installationColumns, rowsLoadingIds])

  const noDataContent = useMemo(
    () => (
      <div className={classnames(commonClasses.noDataContentInTheMiddleOfTheTable, commonClasses.noDataDark)}>
        <div>{t(noDataContentText)}</div>
        <br />
      </div>
    ),
    [commonClasses, t, noDataContentText]
  )

  useEffect(() => {
    if (bulkUpdateBookedMediaWasUpdated) {
      dispatch(clearBulkUpdateBookedMedia())
    }
  }, [dispatch, bulkUpdateBookedMediaWasUpdated])

  useEffect(() => {
    // members are used in UploadedFilesCell
    dispatch(getControllerMembers({ controller: controllerId }))

    return () => {
      dispatch(clearControllerMembers())
    }
  }, [dispatch, controllerId])

  if (showNoData) {
    // TableDataLoader shows no data only in case the data was loaded and there is no data
    // this covers custom case to show no data message
    return noDataContent
  }

  return (
    <TableDataLoader
      itemsLength={formattedInstallationBookedMediaReport?.length}
      errorSelector={getInstallationBookedMediaReportErrorSelector}
      wasLoadedSelector={installationBookedMediaReportWasLoadedSelector}
      isLoadingSelector={getInstallationBookedMediaReportIsLoadingSelector}
      skeletonProps={{ cols: installationColumns }}
      additionalDataIsLoading={!controllerMembersWasLoaded}
      noDataContent={noDataContent}
    >
      <ExpandableRowsOpenStatesProvider>
        <Table
          className={classes.installationReportTable}
          data={formattedInstallationBookedMediaReport}
          rowTemplate={rowTemplate}
          hideFooterRow
          itemUpdatingId={itemUpdatingId}
          rowLoadingIds={rowsLoadingIds}
          expandableRowsOpenStatesContext={ExpandableRowsOpenStatesContext}
        />
      </ExpandableRowsOpenStatesProvider>
    </TableDataLoader>
  )
}

export default InstallationReportTable

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '../../../../../../../../../helpers/numbers'

import useStyles from '../styles'

const Dimensions = ({ dimensions, isPdfOnly }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const getDimensionText = useCallback(
    ({ width, height }) => {
      const hasWidth = (width && width > 0) || width !== '0.00'
      const hasHeight = (height && height > 0) || height !== '0.00'

      if (hasWidth && hasHeight) {
        return `${t('W')}: ${formatNumber(width)}${isPdfOnly ? 'mm' : 'px'} x ${t('H')}: ${formatNumber(height)}${isPdfOnly ? 'mm' : 'px'}`
      } else if (hasWidth) {
        return `${t('W')}: ${formatNumber(width)}${isPdfOnly ? 'mm' : 'px'}`
      } else if (hasHeight) {
        return `${t('H')}: ${formatNumber(height)}${isPdfOnly ? 'mm' : 'px'}`
      }
    },
    [isPdfOnly, t]
  )

  return (
    <>
      <h5>{t('Dimensions')}</h5>
      {dimensions.map(dimension => {
        return <p className={classes.requirementNum}>{getDimensionText(dimension)}</p>
      })}
    </>
  )
}

Dimensions.propTypes = {
  dimensions: PropTypes.array,
  isPdfOnly: PropTypes.bool
}

export default Dimensions

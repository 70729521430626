import { CHIP_COLORS } from '../../../../constants/other'

export const PAID = 'paid'
export const AWAITING_PAYMENT = 'awaiting_payment'
export const CAN_GO_LIVE = 'can_go_live'
export const OVERDUE = 'overdue'
export const PAID_IN_PART = 'paid_in_part'

export const paymentStatusesOptions = [
  {
    value: PAID,
    label: 'Paid',
    color: CHIP_COLORS.green
  },
  {
    value: AWAITING_PAYMENT,
    label: 'Awaiting Payment',
    color: CHIP_COLORS.grey
  },
  {
    value: CAN_GO_LIVE,
    label: 'Can Go Live',
    color: CHIP_COLORS.green
  },
  {
    value: OVERDUE,
    label: 'Overdue',
    color: CHIP_COLORS.red
  },
  {
    value: PAID_IN_PART,
    label: 'Partial Payment Received',
    color: CHIP_COLORS.yellow
  },
  {
    value: null,
    label: 'Payment Status Unknown',
    color: CHIP_COLORS.grey
  }
]
